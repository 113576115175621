import React from 'react'
import ReactDOM from 'react-dom'
import RouterTitle from 'react-router-title'
import './assets/styles/bootstrap/index.scss'
import 'react-toastify/dist/ReactToastify.css'
import './assets/styles/index.scss'
import './index.scss'
import { BrowserRouter as Router } from 'react-router-dom'
import { routes } from './router/config'
import App from './App/index'
import reportWebVitals from './utils/reportWebVitals'
import './i18n'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <RouterTitle pageTitle="AppCapital" routesConfig={routes} />
      <App />
    </Router>
  </React.StrictMode>,
  document.getElementById('root')
)

if (__DEV__) {
  reportWebVitals(console.log)
}
