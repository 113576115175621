import React, { ReactElement } from 'react'
import { Button as BootstrapButton, Spinner } from 'react-bootstrap'
import './index.scss'
import { ButtonProps } from './types'

const Button: React.FunctionComponent<ButtonProps> = ({
  className = '',
  value = 'Ok',
  onPress = () => {},
  disabled = false,
  isLoading = false,
  variant = 'primary',
  icon,
}): ReactElement => (
  <BootstrapButton
    className={`${className} Button text-md`}
    onClick={onPress}
    size="lg"
    disabled={isLoading || disabled}
    variant={variant}
  >
    {value}
    {isLoading && (
      <Spinner
        className="Spinner"
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    )}
    {!isLoading && icon && <span className="Icon material-icons">{icon}</span>}
  </BootstrapButton>
)

export default Button
