import React, { ReactElement, useEffect } from 'react'
import './index.scss'
import Form from 'react-bootstrap/Form'
import { TextareaProps } from './types'

const Textarea: React.FunctionComponent<TextareaProps> = ({
  className = '',
  label = null,
  placeholder = null,
  autosize = true,
  controlId = 'Textarea',
  error = '',
  useHelperText = false,
  value,
  onChange,
  onFocus,
  onBlur,
  maxLength,
}) : ReactElement => {
  useEffect(() => {
    const element = document.getElementsByClassName('Textarea-autosize')[0] as HTMLElement
    if (!element) return
    const minHeight = element.scrollHeight
    element.addEventListener('input', () => {
      element.style.overflow = 'hidden'
      if (element.scrollHeight > minHeight) {
        element.style.height = String(0)
        element.style.height = `${element.scrollHeight}px`
        if (element.scrollHeight < minHeight) {
          element.style.height = `${minHeight}px`
        }
      }
    })
  }, [autosize])
  return (
    <Form.Group className={`${className} Textarea`} controlId={controlId}>
      {
      label
        ? (<Form.Label>{label}</Form.Label>)
        : null
    }
      <Form.Control
        className={`${autosize ? 'Textarea-autosize' : ''}`}
        as="textarea"
        type="text"
        maxLength={maxLength}
        placeholder={placeholder ?? ''}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {
        useHelperText && (
          <Form.Text className="TextHelper text-xs color-error py-1 mt-0 d-block">
            {error}
          </Form.Text>
        )
      }
    </Form.Group>
  )
}

export default Textarea
