export interface ApiErrorProps {
  status: number | null,
  title: string | null,
  message: string,
}

class ApiError extends Error {
  public status: number | null

  public title: string | null

  constructor({
    status,
    title,
    message,
  }: ApiErrorProps) {
    super(message)
    this.status = status
    this.title = title
  }
}

export default ApiError
