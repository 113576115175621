import React, { ReactElement } from 'react'
import './index.scss'
import Form from 'react-bootstrap/Form'
import { InputProps } from './types'

const Input: React.FunctionComponent<InputProps> = ({
  className = '',
  label = null,
  placeholder = null,
  type = 'text',
  controlId = 'Input',
  error = '',
  useHelperText = false,
  value,
  onChange,
  onFocus,
  onBlur,
  maxLength,
}) : ReactElement => (
  <Form.Group className={`${className} Input`} controlId={controlId}>
    {
      label
        ? (<Form.Label>{label}</Form.Label>)
        : null
    }
    <Form.Control
      type={type}
      placeholder={placeholder ?? ''}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    {
      useHelperText && (
        <Form.Text className="TextHelper text-xs color-error py-1 mt-0 d-block">
          {error}
        </Form.Text>
      )
    }
  </Form.Group>
)

export default Input
