import rest from '../../REST'
import { SendFeedbackParams } from './types'

export default {
  sendFeedback: async ({
    from,
    message,
    application,
  } : SendFeedbackParams) => rest.post(
    '/notifications/feedback/email',
    {
      from,
      message,
      application,
    },
  ),
}
