import axios from 'axios'
import { toast } from 'react-toastify'
import i18n from '../i18n'
import {
  getResponseResult, getServerError, getUnknownError, getError, getUrl,
} from './utils'
import { ApiResponseResult } from './types'

const request = async (
  method: 'get' | 'post',
  endpoint: string,
  params: { [key: string]: any } = {},
) : Promise<ApiResponseResult> => {
  const url = getUrl(endpoint)
  try {
    switch (method) {
      case 'post':
        return getResponseResult(await axios.post(url, params))
      case 'get':
      default:
        return getResponseResult(await axios.get(url, {
          params,
        }))
    }
  } catch (error: import('axios').AxiosError | Error | unknown) {
    let preparedError
    if (axios.isAxiosError(error)) {
      const status = error.response?.status ?? 0
      if (!status) {
        preparedError = getUnknownError()
      } else {
        const statusCodeGroup = Math.floor(status / 100)
        switch (statusCodeGroup) {
          case 4: // 4xx status codes
            if (error.response?.data?.message) {
              preparedError = getError({
                status,
                message: error.response?.data?.message,
                title: i18n.t('api.errors.400.title'),
              })
            } else {
              preparedError = getUnknownError()
            }
            break
          case 5: // 5xx status codes
            preparedError = getServerError(status)
            break
          default:
            preparedError = getUnknownError()
        }
      }
    } else {
      preparedError = getUnknownError()
    }

    toast(preparedError.message, {
      position: 'top-center',
      autoClose: 12000,
      type: 'error',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: 'colored',
    })
    throw preparedError
  }
}

export default {
  get: async (endpoint: string, params: { [key: string]: any } = {}) => request('get', endpoint, params),
  post: async (endpoint: string, params: { [key: string]: any } = {}) => request('post', endpoint, params),
}
