import * as punycode from 'punycode'
import rules from './rules'
import ERROR_MESSAGES from './ERROR_MESSAGES'
import {
  ValidationError,
  IDataValidator,
  IDataValidation,
  ValidationResponse,
  DataValidationMethod,
} from './types'
import getValueByPath from '../../utils/getValueByPath'

function staticImplements<T>() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (constructor: T) => {}
}

@staticImplements<IDataValidation>()
export default class DataValidator implements IDataValidator {
  #validationSet: DataValidationMethod[] = []

  constructor(methods: DataValidationMethod[]) {
    this.pushMethods(methods)
  }

  pushMethods(methods: DataValidationMethod[]) {
    this.#validationSet.push(...methods)
  }

  validate(value: string): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const errors: ValidationError[] = []

      this.#validationSet.forEach((validationMethod) => {
        const result = validationMethod(value)
        if (!result.success && result.error) {
          errors.push(result.error)
        }
      })

      if (errors.length > 0) {
        reject(errors)
      } else {
        resolve(true)
      }
    })
  }

  static isNotEmpty(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.NOT_EMPTY(),
    }

    const success = rules.NOT_EMPTY(value)
    return {
      success,
      error: success ? undefined : defaultError,
    }
  }

  static isEmail(value: string): ValidationResponse {
    const defaultError = {
      message: ERROR_MESSAGES.EMAIL(),
    }

    const success = rules.EMAIL(punycode.toUnicode(value))
    return {
      success,
      error: success ? undefined : defaultError,
    }
  }

  static isFeedback(value: string): ValidationResponse {
    let defaultError

    const success = ['FEEDBACK.HAS.LENGTH'].every((path) => {
      const rule = getValueByPath(rules, path)
      defaultError = {
        message: getValueByPath(ERROR_MESSAGES, path)(),
      }
      return rule(value)
    })
    return {
      success,
      error: success ? undefined : defaultError,
    }
  }
}

export const emailValidator = new DataValidator([
  DataValidator.isNotEmpty,
  DataValidator.isEmail,
])

export const feedbackValidator = new DataValidator([
  DataValidator.isNotEmpty,
  DataValidator.isFeedback,
])
