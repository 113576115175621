import React from 'react'
import Support from '../views/App/SupportFeedback'
import App from '../views/App'
import NotFound from '../views/Errors/NotFound'

export const routes = [
  {
    path: '/:appName',
    element: <App />,
    title: 'App',
    children: [
      {
        path: 'support',
        element: <Support />,
        title: 'Support',
      },
    ],
  },
  {
    path: '/*',
    element: <NotFound />,
    title: 'Not Found',
  },
]
