import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import * as en from './locales/en/translation.json'

const resources = {
  en: {
    translation: en,
  },
}

const defaultLanguage = 'en'

i18n
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: defaultLanguage,
    lng: defaultLanguage,
    debug: __DEV__,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
