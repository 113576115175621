import React, { ReactElement } from 'react'
import './index.scss'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import i18n from '../../../i18n'

const NotFound: React.FunctionComponent = () : ReactElement => (
  <Container className="NotFound">
    <Row className="h-100 justify-content-center row align-items-center">
      <Col md="auto">
        <h1 className="text-center text-3xl">
          404
        </h1>
        <h2 className="text-center text-xl">
          {i18n.t('view.errors.404.title')}
        </h2>
        <p className="text-center text-md pt-4">
          {i18n.t('view.errors.404.description')}
        </p>
      </Col>
    </Row>
  </Container>
)

export default NotFound
