import { AppName } from '../CONSTANTS/APP_NAME'

const appNames: readonly AppName[] = <const> [
  'Recordeon',
  'Filtry',
  'EasySnap',
  'GifYou',
  'TeasEar',
  'Voicelator',
]

export default appNames
