import React, { ReactElement, useCallback, useState } from 'react'
import './index.scss'
import { useParams } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Col from 'react-bootstrap/Col'
import { toast } from 'react-toastify'
import i18n from '../../../i18n'
import Button from '../../../components/Button'
import api from '../../../api'
import { appNames } from '../../../config'
import Textarea from '../../../components/Textarea'
import Input from '../../../components/Input'
import { feedbackValidator, emailValidator } from '../../../services/dataValidator'
import {
  State,
  FormValues,
  FeedbackValidatorKey,
  FeedbackValidators,
  ValidationErrors,
} from './types'

const validators: FeedbackValidators = {
  email: emailValidator,
  feedback: feedbackValidator,
}

const defaultState: State = {
  formValues: {
    email: '',
    feedback: '',
  },
  focused: null,
  waitingForRequest: false,
  errors: [],
}

const SupportFeedback: React.FunctionComponent = () : ReactElement => {
  const { appName } = useParams()

  const [state, setState] = useState<State>(defaultState)

  const formattedAppName = useCallback(
    () => appNames.find((app) => app.toLowerCase() === appName?.toLowerCase()) ?? '',
    [appNames, appName],
  )

  const buttonDisabled = useCallback(
    () => !state.formValues.email || !state.formValues.feedback,
    [state.formValues.email, state.formValues.feedback],
  )

  const toggleWaitingForRequest = useCallback((value: boolean) => {
    setState((prevState) => ({
      ...prevState,
      waitingForRequest: value,
    }))
  }, [])

  const handleChange = useCallback((
    prop: keyof FormValues,
  ) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setState((prevState) => ({
      ...prevState,
      formValues: { ...prevState.formValues, [prop]: event.target.value },
    }))
  }, [])

  const handleFocus = useCallback((prop: keyof FormValues) => () => {
    setState((prevState) => ({
      ...prevState,
      focused: prop,
      errors: prevState.errors.filter((error) => error.key !== prop),
    }))
  }, [])

  const handleBlur = useCallback((prop: keyof FormValues) => () => {
    setState((prevState) => ({
      ...prevState,
      focused: prop === prevState.focused ? null : prevState.focused,
    }))
  }, [])

  const clearFormData = useCallback((prop?: keyof FormValues) => {
    setState((prevState) => {
      let { formValues } = prevState
      if (prop) {
        formValues[prop] = ''
      } else {
        formValues = defaultState.formValues
      }
      return {
        ...prevState,
        formValues,
      }
    })
  }, [])

  const getErrorMessage = useCallback(
    (key: keyof FormValues) => state.errors.find((error) => error.key === key)?.message ?? '',
    [state.errors],
  )

  const onSubmit = useCallback(async () => {
    if (!appName) return
    toggleWaitingForRequest(true)
    const validationResponses = await Promise.allSettled(
      Object.keys(validators)
        .map(
          (key) => validators[key as FeedbackValidatorKey]
            .validate(
              state.formValues[key as keyof FormValues],
            ),
        ),
    )
    const errors = validationResponses.reduce((acc: ValidationErrors[], response, index) => {
      if (response.status === 'rejected') {
        acc.push({
          key: Object.keys(validators)[index] as FeedbackValidatorKey,
          message: response.reason[0].message,
        })
      }
      return acc
    }, [])

    if (errors.length > 0) {
      setState((prevState) => ({ ...prevState, errors }))
      toggleWaitingForRequest(false)
      return
    }

    try {
      await api.support.sendFeedback({
        from: state.formValues.email,
        message: state.formValues.feedback,
        application: appName.toLowerCase(),
      })
      clearFormData('feedback')
      toast(i18n.t('view.support.toast.success'), {
        position: 'top-center',
        autoClose: 10000,
        type: 'success',
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: 'colored',
      })
    } catch {} finally {
      toggleWaitingForRequest(false)
    }
  }, [state.formValues.email, state.formValues.feedback, appName, state.errors])

  return (
    <Container className="SupportFeedback">
      <Row className="h-100 justify-content-center row align-items-center">
        <Col className="SupportFeedback-col" md="auto">
          <h1 className="text-xl">
            {i18n.t('view.support.title', { ProductName: formattedAppName() })}
          </h1>
          <p className="text-md opacity-50">
            {i18n.t('view.support.description')}
          </p>
          <Form className="mt-3">
            <Input
              value={state.formValues.email}
              onChange={handleChange('email')}
              onFocus={handleFocus('email')}
              onBlur={handleBlur('email')}
              error={getErrorMessage('email')}
              useHelperText
              controlId="formEmail"
              label={i18n.t('view.support.form.email.label')}
              placeholder={i18n.t('view.support.form.email.placeholder')}
              maxLength={255}
            />
            <Textarea
              value={state.formValues.feedback}
              onChange={handleChange('feedback')}
              onFocus={handleFocus('feedback')}
              onBlur={handleBlur('feedback')}
              error={getErrorMessage('feedback')}
              useHelperText
              className="mt-2"
              controlId="formFeedback"
              label={i18n.t('view.support.form.feedback.label')}
              placeholder={i18n.t('view.support.form.feedback.placeholder')}
              maxLength={2000}
            />
            <div className="d-grid mt-3">
              <Button
                disabled={buttonDisabled()}
                isLoading={state.waitingForRequest}
                onPress={onSubmit}
                icon="send"
                value={i18n.t('view.support.button.value')}
              />
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  )
}

export default SupportFeedback
