import { AxiosResponse } from 'axios'
import ApiError, { ApiErrorProps } from '../classes/ApiError'
import i18n from '../i18n'
import { ApiResponseResult } from './types'

export const getUrl = (path: string) =>
  `${
    process.env.REACT_APP_IS_LOCALHOST
      ? `${process.env.REACT_APP_CORS_ANIWHERE}/`
      : ''
  }${new URL(path, process.env.REACT_APP_API_URL).href}`

export const getError = ({ status, message, title }: ApiErrorProps): ApiError =>
  new ApiError({
    status,
    message,
    title,
  })

export const getUnknownError = (): ApiError =>
  getError({
    status: null,
    message: i18n.t('api.errors.eUnknown.message'),
    title: i18n.t('api.errors.eUnknown.title'),
  })

export const getServerError = (status: number): ApiError =>
  getError({
    status,
    message: i18n.t('api.errors.500.message'),
    title: i18n.t('api.errors.500.title'),
  })

export const getResponseResult = (
  response: AxiosResponse
): ApiResponseResult => ({
  data: response.data.data,
  status: response.data.status ?? 'OK',
  success: response.data.success ?? true,
})
