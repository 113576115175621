import React, { ReactElement } from 'react'
import { useParams, Outlet } from 'react-router-dom'
import { appNames } from '../../config'
import NotFound from '../Errors/NotFound'

const SupportFeedback: React.FunctionComponent = () : ReactElement => {
  const { appName } = useParams()

  if (
    appName && !appNames.map(
      (app) => app.toLowerCase(),
    ).includes(appName.toLowerCase())
  ) {
    return (
      <NotFound />
    )
  }

  return (
    <Outlet />
  )
}

export default SupportFeedback
