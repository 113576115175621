import React, { ReactElement } from 'react'
import './index.scss'
import { useRoutes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import { routes } from '../router/config'
import CompanyLogotype from '../assets/images/main/CompanyLogotype.png'

const App: React.FunctionComponent = (): ReactElement => (
  <div className="App">
    <ToastContainer />
    <main className="App-main">
      <div className="App-container d-flex flex-column">
        <header className="App-header">
          <div className="logo">
            <a href="https://appcapital.vc">
              <img
                className="Header-companyLogotype"
                src={CompanyLogotype}
                alt=""
              />
            </a>
          </div>
        </header>
        <main className="App-page d-flex flex-grow-1">{useRoutes(routes)}</main>
      </div>
    </main>
  </div>
)

export default App
